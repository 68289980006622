import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, Box, Container, 
  Paper, CircularProgress, Alert, Select, MenuItem, FormControl, InputLabel,
  TextField, Chip, Tabs, Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import AuditChecklistMapping from './audit_checklist';
import { handleClaudeSubmit } from './modules/claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from './modules/openai_4o_module';
import DemoForm from './DemoForm';
import ISOForm from './ISO_27002_2022_Form';
import NistForm from './NISTForm';
import CustomForm from './CustomForm';

// Styled components
const aiLabels = {
  'openai': 'OpenAI',
  'claude': 'Claude'
};

// Tab Panel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MarkdownContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '& pre': {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
  },
  '& code': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
}));

function UnifiedAIForm() {
  const [currentTab, setCurrentTab] = useState(0); 

  // Existing state management
  const [files, setFiles] = useState([]);
  const [output, setOutput] = useState(null);
  const [overallAssessmentOutcome, setOverallAssessmentOutcome] = useState(null);
  const [organizationIdentificationOutcome, setOrganizationIdentificationOutcome] = useState(null);
  const [systemProcessIdentificationOutcome, setSystemProcessIdentificationOutcome] = useState(null);
  const [timestampOutcome, setTimestampOutcome] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedControl, setSelectedControl] = useState('');
  const [selectedAI, setSelectedAI] = useState('openai');
  const [error, setError] = useState(null);
  const [controlDescription, setControlDescription] = useState('');

  const fileInputRef = useRef(null);
  const bottomRef = useRef(null);

  // Tab change handler
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    // Reset form state when switching tabs
    setFiles([]);
    setOutput(null);
    setError(null);
    setSelectedControl('');
    setControlDescription('');
    setResponseData(null);
  };

  // Effects
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files.length > 0 && selectedControl !== '');
  }, [files, selectedControl]);

  // Event handlers
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    
    // Only validate for OpenAI
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }
    
    setFiles(newFiles);
    setError(null);
  };

  const handleControlChange = (event) => {
    setSelectedControl(event.target.value);
    setControlDescription(AuditChecklistMapping[event.target.value] || '');
  };

  const handleDescriptionChange = (event) => {
    setControlDescription(event.target.value);
  };

  const handleAIChange = (event) => {
    const newAI = event.target.value;
    setSelectedAI(newAI);
    setOutput(null);
    
    // Only validate files if switching to OpenAI and files exist
    if (newAI === 'openai' && files.length > 0) {
      const { isValid, errors } = validateFiles(files);
      if (!isValid) {
        setError(errors.join('\n'));
        setFiles([]); // Only clear files if validation fails
      }
    } else {
      setError(null); // Clear any existing errors
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateResponseStates = (data) => {
    setOverallAssessmentOutcome(data.overallAssessment);
    setOrganizationIdentificationOutcome(data.organizationIdentification);
    setSystemProcessIdentificationOutcome(data.systemProcessIdentification);
    setTimestampOutcome(data.timestamp);
    setResponseData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setOutput('');
    setError(null);
    setResponseData(null);
    updateResponseStates({
      overallAssessment: null,
      organizationIdentification: null,
      systemProcessIdentification: null,
      timestamp: null
    });
  
    try {
      const modifiedMapping = {
        ...AuditChecklistMapping,
        [selectedControl]: controlDescription
      };

      if (selectedAI === 'claude') {
        const { response, parsedData } = await handleClaudeSubmit(
          files,
          selectedControl,
          modifiedMapping
        );
        setOutput(response);
        updateResponseStates(parsedData);
      } else {
        const response = await handleOpenAISubmit(
          files,
          selectedControl,
          modifiedMapping,
          (data) => {
            updateResponseStates(data);
          }
        );
        setOutput(response);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(error.message);
      setOutput(null);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  const getAlert = (outcome, message, marginTop = 0) => {
    if (!responseData) return null;
    
    const severityMap = {
      'ACCEPT': 'success',
      'WARNING': 'warning',
      'REJECT': 'error'
    };
    
    return outcome && (
      <Alert severity={severityMap[outcome]} sx={{ mt: marginTop }}>
        {message}
      </Alert>
    );
  };

  // Render component
  return (
    <Container maxWidth="md">
      <Box sx={{ pt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, bgcolor: 'background.paper' }}>
          {/* Tabs moved to top */}
          <Box sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            '& .MuiTabs-root': {
              minHeight: '48px',
            }
          }}>
            <Tabs 
              value={currentTab} 
              onChange={handleTabChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'flex-start',
                },
              }}
              TabIndicatorProps={{
                sx: {
                  height: 3
                }
              }}
            >
              <Tab 
                label="ISO 27002 2022" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: '1rem',
                  minHeight: '48px',
                  py: 1
                }}
              />
              <Tab 
                label="Generic" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: '1rem',
                  minHeight: '48px',
                  py: 1
                }}
              />
              <Tab 
                label="Custom" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: '1rem',
                  minHeight: '48px',
                  py: 1
                }}
              />
              <Tab 
                label="NIST Cybersecurity Framework 2.0" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: '1rem',
                  minHeight: '48px',
                  py: 1
                }}
              />
            </Tabs>
          </Box>

          <Box sx={{ p: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img 
                src="logo.png" 
                alt="Logo"
                style={{ maxWidth: '370px', height: 'auto' }}
              />
            </Box>

            {/* ISO Tab Panel */}
            <TabPanel value={currentTab} index={0}>
              <ISOForm
                setOutput={setOutput} 
                setOverallAssessmentOutcome={setOverallAssessmentOutcome}
                setOrganizationIdentificationOutcome={setOrganizationIdentificationOutcome}
                setSystemProcessIdentificationOutcome={setSystemProcessIdentificationOutcome}
                setTimestampOutcome={setTimestampOutcome}
                setResponseData={setResponseData}/>
            </TabPanel>

            {/* Generic Tab Panel */}
            <TabPanel value={currentTab} index={1}>
              <DemoForm 
                setOutput={setOutput}
                setOverallAssessmentOutcome={setOverallAssessmentOutcome}
                setOrganizationIdentificationOutcome={setOrganizationIdentificationOutcome}
                setSystemProcessIdentificationOutcome={setSystemProcessIdentificationOutcome}
                setTimestampOutcome={setTimestampOutcome}
                setResponseData={setResponseData}/>
            </TabPanel>
            
            {/* Custom Tab Panel */}
            <TabPanel value={currentTab} index={2}>
              <CustomForm
                  setOutput={setOutput}
                  setOverallAssessmentOutcome={setOverallAssessmentOutcome}
                  setOrganizationIdentificationOutcome={setOrganizationIdentificationOutcome}
                  setSystemProcessIdentificationOutcome={setSystemProcessIdentificationOutcome}
                  setTimestampOutcome={setTimestampOutcome}
                  setResponseData={setResponseData}/>
            </TabPanel>
            
            {/* Nist Tab Panel */}
            <TabPanel value={currentTab} index={3}>
              <NistForm
                  setOutput={setOutput}
                  setOverallAssessmentOutcome={setOverallAssessmentOutcome}
                  setOrganizationIdentificationOutcome={setOrganizationIdentificationOutcome}
                  setSystemProcessIdentificationOutcome={setSystemProcessIdentificationOutcome}
                  setTimestampOutcome={setTimestampOutcome}
                  setResponseData={setResponseData}/>
            </TabPanel>
          </Box>
        </Paper>
      </Box>

      {/* Results Section */}
      {(output || isLoading) && (
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2, bgcolor: 'background.paper', mb: 4 }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h5" component="h2" gutterBottom color="primary" fontWeight="bold" textAlign="center">
                Analysis Results <Chip label={aiLabels[selectedAI]} variant="outlined" />
              </Typography>
              <MarkdownContainer>
                {getAlert(overallAssessmentOutcome, responseData?.reasoning?.overall)}
                {getAlert(organizationIdentificationOutcome, responseData?.reasoning?.organization, 2)}
                {getAlert(systemProcessIdentificationOutcome, responseData?.reasoning?.systemProcess, 2)}
                {getAlert(timestampOutcome, responseData?.reasoning?.timestamp, 2)}
                <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
                  {output}
                </ReactMarkdown>
              </MarkdownContainer>
            </>
          )}
          <div ref={bottomRef} />
        </Paper>
      )}
    </Container>
  );
}

export default UnifiedAIForm;