import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, Box, CircularProgress, Alert, Select, MenuItem, FormControl, InputLabel,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AuditChecklistMapping from './audit_checklist';
import { handleClaudeSubmit } from './modules/claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from './modules/openai_4o_module';

// Styled components
const aiLabels = {
  'openai': 'OpenAI',
  'claude': 'Claude'
};

// Tab Panel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MarkdownContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '& pre': {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
  },
  '& code': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
}));

function ISOForm(props) {
  // Existing state management
  const [files, setFiles] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedControl, setSelectedControl] = useState('');
  const [selectedAI, setSelectedAI] = useState('openai');
  const [error, setError] = useState(null);
  const [controlDescription, setControlDescription] = useState('');

  const fileInputRef = useRef(null);
  const bottomRef = useRef(null);

  // Effects
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files.length > 0 && selectedControl !== '');
  }, [files, selectedControl]);

  // Event handlers
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    
    // Only validate for OpenAI
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }
    
    setFiles(newFiles);
    setError(null);
  };

  const handleControlChange = (event) => {
    setSelectedControl(event.target.value);
    setControlDescription(AuditChecklistMapping[event.target.value] || '');
  };

  const handleDescriptionChange = (event) => {
    setControlDescription(event.target.value);
  };

  const handleAIChange = (event) => {
    const newAI = event.target.value;
    setSelectedAI(newAI);
    props.setOutput(null);
    
    // Only validate files if switching to OpenAI and files exist
    if (newAI === 'openai' && files.length > 0) {
      const { isValid, errors } = validateFiles(files);
      if (!isValid) {
        setError(errors.join('\n'));
        setFiles([]); // Only clear files if validation fails
      }
    } else {
      setError(null); // Clear any existing errors
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateResponseStates = (data) => {
    props.setOverallAssessmentOutcome(data.overallAssessment);
    props.setOrganizationIdentificationOutcome(data.organizationIdentification);
    props.setSystemProcessIdentificationOutcome(data.systemProcessIdentification);
    props.setTimestampOutcome(data.timestamp);
    props.setResponseData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    props.setOutput('');
    setError(null);
    props.setResponseData(null);
    updateResponseStates({
      overallAssessment: null,
      organizationIdentification: null,
      systemProcessIdentification: null,
      timestamp: null
    });
  
    try {
      const modifiedMapping = {
        ...AuditChecklistMapping,
        [selectedControl]: controlDescription
      };

      if (selectedAI === 'claude') {
        const { response, parsedData } = await handleClaudeSubmit(
          files,
          selectedControl,
          modifiedMapping
        );
        props.setOutput(response);
        updateResponseStates(parsedData);
      } else {
        const response = await handleOpenAISubmit(
          files,
          selectedControl,
          modifiedMapping,
          (data) => {
            updateResponseStates(data);
          }
        );
        props.setOutput(response);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(error.message);
      props.setOutput(null);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  // Render component
  return (
    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
      <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
        NEN-EN-ISO_IEC 27002_2022
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
        Upload your documents and get an instant AI-generated quality analysis report.
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        {/* AI Selection */}
        <FormControl fullWidth>
          <InputLabel id="ai-select-label">Select AI Assistant</InputLabel>
          <Select
            labelId="ai-select-label"
            id="ai-select"
            value={selectedAI}
            label="Select AI Assistant"
            onChange={handleAIChange}
          >
            <MenuItem value="claude">{aiLabels['claude']}</MenuItem>
            <MenuItem value="openai">{aiLabels['openai']}</MenuItem>
          </Select>
        </FormControl>

        {/* Control Selection */}
        <FormControl fullWidth>
          <InputLabel id="control-select-label">Select your organizational control</InputLabel>
          <Select
            labelId="control-select-label"
            id="control-select"
            value={selectedControl}
            label="Select your organizational control"
            onChange={handleControlChange}
          >
            {Object.keys(AuditChecklistMapping).map((control) => (
              <MenuItem key={control} value={control}>{control}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Control Description Text Area */}
        {selectedControl && (
          <TextField
            fullWidth
            multiline
            variant="outlined"
            label="Control Description"
            value={controlDescription}
            onChange={handleDescriptionChange}
            InputProps={{
              readOnly: false,
            }}
          />
        )}

        {/* File Input */}
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={handleFileButtonClick}
          >
            Upload supporting evidence
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files)}
          </FileNameDisplay>
        </CustomFileInput>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 2, fontWeight: 'bold' }}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Analyze supporting evidence'}
        </Button>
      </StyledForm>
    </Typography>
  );
}

export default ISOForm;