import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import {
  Construction,
  DarkMode,
  LightMode,
  Settings,
  ArrowForward
} from '@mui/icons-material';

// Define theme settings
const getThemeSettings = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#ed7d31',
          },
          background: {
            default: '#f5f5f5',
            paper: '#ffffff',
          },
        }
      : {
          primary: {
            main: '#f0af84',
          },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
          },
        }),
  },
});

export default function UnderConstructionPage() {
  const [mode, setMode] = React.useState('light');
  
  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  const theme = React.useMemo(() => createTheme(getThemeSettings(mode)), [mode]);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* App Bar */}
        <AppBar position="static" color="default" elevation={1}>
          <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Construction />
                <img 
                    src="logo.png" 
                    alt="AuditAgent Logo"
                    style={{ maxWidth: '150px', height: 'auto' }}
                />
              </Box>
              <IconButton onClick={toggleColorMode} color="inherit">
                {mode === 'dark' ? <LightMode /> : <DarkMode />}
              </IconButton>
            </Box>
          </Container>
        </AppBar>

        {/* Main Content */}
        <Container component="main" sx={{ flexGrow: 1, py: 8 }}>
          <Box sx={{ textAlign: 'center', maxWidth: 'md', mx: 'auto' }}>
            <Construction sx={{ fontSize: 80, mb: 4, color: 'primary.main' }} />
            <Typography variant="h3" component="h1" gutterBottom>
              Under Construction
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              We're working hard to bring you something amazing. Our website is currently
              undergoing scheduled maintenance and updates.
            </Typography>

            {/* Progress Indicator */}
            <Box sx={{ maxWidth: 'sm', mx: 'auto', mb: 6 }}>
              <LinearProgress variant="indeterminate" />
            </Box>
          </Box>
        </Container>

        {/* Footer */}
        <Box component="footer" sx={{ py: 3, bgcolor: 'background.paper', mt: 'auto' }}>
          <Container>
            <Typography variant="body2" color="text.secondary" align="center">
              © {new Date().getFullYear()} AuditAgent AI. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}