import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Typography,
  Paper,
  Avatar,
  Alert,
  Container,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { GitHub as GitHubIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

// Create a theme instance (you can customize this)
const theme = createTheme();

const GitHubOAuth = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // These would typically come from environment variables
  const CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID;
  const REDIRECT_URI = 'https://staging.auditagent.ai/agents';

  const initiateGitHubLogin = () => {
    const githubAuthUrl = `https://github.com/login/oauth/authorize?scope=user:email&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
    window.location.href = githubAuthUrl;
  };

  useEffect(() => {
    const handleCallback = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      
      if (code) {
        try {
          const tokenResponse = await fetch('/api/github/token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code })
          });
          
          const tokenData = await tokenResponse.json();
          
          if (tokenData.access_token) {
            // Store the token in localStorage
            localStorage.setItem('github_token', tokenData.access_token);
            setAccessToken(tokenData.access_token);
            
            const userResponse = await fetch('https://api.github.com/user', {
              headers: {
                'Authorization': `Bearer ${tokenData.access_token}`,
                'Accept': 'application/json'
              }
            });
            
            const userData = await userResponse.json();
            setUserData(userData);

            // Navigate to the intended route or default to /agents
            const { from } = location.state || { from: '/agents' };
            navigate(from, { replace: true });
          }
        } catch (err) {
          setError('Failed to authenticate with GitHub');
          console.error(err);
        }
      }
    };
    
    handleCallback();
  }, [navigate, location]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >            
            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
              AuditAgent AI Authentication
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 3 }}>
                {error}
              </Alert>
            )}

            {userData ? (
              <Stack spacing={3} sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar
                    src={userData.avatar_url}
                    alt={userData.name}
                    sx={{ width: 64, height: 64 }}
                  />
                  <Box>
                    <Typography variant="h6">{userData.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {userData.login}
                    </Typography>
                  </Box>
                </Box>

                <Divider />

                <List disablePadding>
                  <ListItem>
                    <ListItemText
                      primary="Email"
                      secondary={userData.email || 'Not public'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Followers"
                      secondary={userData.followers}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Following"
                      secondary={userData.following}
                    />
                  </ListItem>
                </List>
              </Stack>
            ) : (
              <Stack spacing={3} sx={{ width: '100%' }}>
                <Typography variant="body1" align="center">
                  Sign in to gain access to the agents.
                </Typography>
                
                <Button
                  variant="contained"
                  onClick={initiateGitHubLogin}
                  startIcon={<GitHubIcon />}
                  fullWidth
                  size="large"
                  sx={{
                    bgcolor: 'grey.900',
                    '&:hover': {
                      bgcolor: 'grey.800',
                    },
                  }}
                >
                  Sign in with GitHub
                </Button>
              </Stack>
            )}
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GitHubOAuth;