import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Link as RouterLink } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

const ScrollLink = React.forwardRef(({ to, ...props }, ref) => {
  const handleClick = (event) => {
    event.preventDefault();
    const element = document.getElementById(to);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return <Button {...props} onClick={handleClick} ref={ref} />;
});

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 1}}
    >
    <Container maxWidth="lg">
      <StyledToolbar variant="dense" disableGutters>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
          <img 
              src="logo.png" 
              alt="AuditAgent Logo"
              style={{ maxWidth: '150px', height: 'auto' }}
          />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ScrollLink to="features" variant="text" color="info" size="small">
              Automated Evidence Quality Analysis
            </ScrollLink>
            <ScrollLink to="features-2" variant="text" color="info" size="small">
              Error Detection
            </ScrollLink>
            <ScrollLink to="features-3" variant="text" color="info" size="small">
              Integration APIs and Plugins
            </ScrollLink>
            <ScrollLink to="highlights" variant="text" color="info" size="small">
              Highlights
            </ScrollLink>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Button
            component={RouterLink}
            to="/agents"
            color="primary"
            variant="contained"
            size="small"
          >
            Demo
          </Button>
        </Box>
        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />
              <MenuItem onClick={() => scrollToSection('features')}>Automated Evidence Quality Analysis</MenuItem>
              <MenuItem onClick={() => scrollToSection('features')}>Error Detection</MenuItem>
              <MenuItem onClick={() => scrollToSection('features')}>Integration APIs and Plugins</MenuItem>
              <MenuItem onClick={() => scrollToSection('highlights')}>Highlights</MenuItem>
              <MenuItem>
                <Button
                  component={RouterLink}
                  to="/agents"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Demo
                </Button>
              </MenuItem>
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </Container>
    </AppBar>
  );
}