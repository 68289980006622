import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, Box, Container, 
  Paper, CircularProgress, Alert
} from '@mui/material';

import { styled } from '@mui/material/styles';
import OpenAI from 'openai';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MarkdownContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '& pre': {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
  },
  '& code': {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
}));

function DemoForm(props) {
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files1.length > 0 && files2.length > 0 && files3.length > 0);
  }, [files1, files2, files3]);

  const handleFileChange = (event, setFiles) => {
    const newFiles = Array.from(event.target.files);
    setFiles(newFiles);
  };

  const handleFileButtonClick = (ref) => {
    ref.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    props.setOutput(''); // Clear previous output
    props.setOverallAssessmentOutcome(null);
  
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
      });
  
      // Step 1: Upload files
      const allFiles = [...files1, ...files2, ...files3];
      if (allFiles.length === 0) {
        throw new Error("No files selected. Please upload files before submitting.");
      }
  
      console.log(`Uploading ${allFiles.length} files...`);
      const fileUploads = await Promise.all(
        allFiles.map(file => 
          openai.files.create({ 
            file, 
            purpose: 'assistants' 
          }).then(response => {
            console.log(`File uploaded: ${file.name}, ID: ${response.id}`);
            return response;
          })
        )
      );
      console.log(`${fileUploads.length} files uploaded successfully.`);
  
      // Step 2: Create a new thread
      console.log("Creating a new thread...");
      const thread = await openai.beta.threads.create();
      console.log("New thread created:", thread.id);
  
      // Step 3: Add a message to the thread with the prompt and file attachments
      console.log("Adding message to thread with file attachments...");
      const messageContent = "Here are my documents, keep in mind this is a single request, I can not respond after your response.";
  
      // Create an array of file attachments
      const attachments = fileUploads.map(file => ({
        file_id: file.id,
        tools: [{type:"file_search"}]
      }));
  
      // Combine text content and file attachments
      console.log(attachments)
      const messageParams = {
        role: "user",
        content: [
          { type: "text", text: messageContent },
        ],
        attachments
      };
  
      try {
        const message = await openai.beta.threads.messages.create(thread.id, messageParams);
        console.log("Message added to thread:", message.id);
      } catch (error) {
        console.error("Error adding message to thread:", error);
        throw error;
      }
  
      // Step 4: Run the assistant on this thread
      console.log("Running the assistant on the thread...");
      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: "asst_1g6pPY3IQTYOxahKf4KHVW6A",
      });
      console.log("Run created:", run.id);
  
      // Poll for the run to complete
      let completedRun;
      while (true) {
        completedRun = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        console.log("Run status:", completedRun.status);
        if (completedRun.status === 'completed') {
          break;
        } else if (completedRun.status === 'failed') {
          throw new Error("Run failed: " + JSON.stringify(completedRun.last_error));
        } else if (completedRun.status === 'requires_action') {
          // Handle required action
          const requiredAction = completedRun.required_action;
          if (requiredAction.type === 'submit_tool_outputs') {
            for (const toolCall of requiredAction.submit_tool_outputs.tool_calls) {
              if (toolCall.function.name === 'post_result') {
                const argument_json = JSON.parse(toolCall.function.arguments);
                props.setOutcome(argument_json['outcome']);
                console.log("post_result function called with arguments:", toolCall.function.arguments);
                
                // Respond to the action
                await openai.beta.threads.runs.submitToolOutputs(thread.id, run.id, {
                  tool_outputs: [{
                    tool_call_id: toolCall.id,
                    output: JSON.stringify({ success: "true" })
                  }]
                });
                
                console.log("Responded to post_result action");
              }
            }
          }
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before polling again
      }
      console.log("Run completed");
  
      // Retrieve and display the assistant's response
      const messages = await openai.beta.threads.messages.list(thread.id);
      const lastAssistantMessage = messages.data
        .filter(message => message.role === 'assistant')
        .pop();
  
      if (lastAssistantMessage && lastAssistantMessage.content.length > 0) {
        props.setOutput(lastAssistantMessage.content[0].text.value);
      } else {
        props.setOutput("No response from the assistant.");
      }
  
      // Clean up: delete the uploaded files
      console.log("Cleaning up...");
      for (const file of fileUploads) {
        await openai.files.del(file.id);
      }
      console.log("Cleanup completed");
  
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      props.setOutput(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  return (
    <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
      <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
        Custom Framework
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
        Upload your documents and get an instant AI-generated quality analysis report.
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef1}
            onChange={(e) => handleFileChange(e, setFiles1)}
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={() => handleFileButtonClick(fileInputRef1)}
          >
            Control Framework Document
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files1)}
          </FileNameDisplay>
        </CustomFileInput>
        
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef2}
            onChange={(e) => handleFileChange(e, setFiles2)}
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={() => handleFileButtonClick(fileInputRef2)}
          >
            Control / Process Document
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files2)}
          </FileNameDisplay>
        </CustomFileInput>
        
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef3}
            onChange={(e) => handleFileChange(e, setFiles3)}
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={() => handleFileButtonClick(fileInputRef3)}
          >
            Supporting Evidence Document
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files3)}
          </FileNameDisplay>
        </CustomFileInput>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 2, fontWeight: 'bold' }}
          disabled={!isFormValid || isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Analyze Documents'}
        </Button>
      </StyledForm>
    </Typography>
  );
}

export default DemoForm;