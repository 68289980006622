import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, CircularProgress, Alert, Select, MenuItem, FormControl, InputLabel,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AuditChecklistMapping from './custom_audit_checklist';
import ProjectToProcessMapping from './project_process_mapping';
import ProcessToControlMapping from './process_control_mapping';
import { handleClaudeSubmit } from './modules/custom_claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from './modules/custom_openai_4o_module';

const aiLabels = {
  'openai': 'OpenAI',
  'claude': 'Claude'
};

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
})); 

function CustomForm(props) {
  // State management
  const [files, setFiles] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAI, setSelectedAI] = useState('openai');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProcess, setSelectedProcess] = useState('');
  const [selectedControl, setSelectedControl] = useState('');
  const [error, setError] = useState(null);
  const [controlDescription, setControlDescription] = useState('');
  const [controlTestSteps, setControlTestSteps] = useState('');
  const [controlFrequency, setControlFrequency] = useState('');

  const fileInputRef = useRef(null);
  const bottomRef = useRef(null);

  // Effects
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files.length > 0 && selectedControl !== '');
  }, [files, selectedControl]);

  // Event handlers
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    
    // Only validate for OpenAI
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }
    
    setFiles(newFiles);
    setError(null);
  };

  // Get available processes for selected project
  const getAvailableProcesses = () => {
    return selectedProject ? ProjectToProcessMapping[selectedProject] || [] : [];
  };

  // Get available controls for selected process
  const getAvailableControls = () => {
    return selectedProcess ? ProcessToControlMapping[selectedProcess] || [] : [];
  };

  // Event handlers
  const handleProjectChange = (event) => {
    const newProject = event.target.value;
    setSelectedProject(newProject);
    setSelectedProcess('');
    setSelectedControl('');
    setControlDescription('');
    setControlTestSteps('');
    setControlFrequency('');
  };

  const handleProcessChange = (event) => {
    const newProcess = event.target.value;
    setSelectedProcess(newProcess);
    setSelectedControl('');
    setControlDescription('');
    setControlTestSteps('');
    setControlFrequency('');
  };

  const handleControlChange = (event) => {
    const newControl = event.target.value;
    setSelectedControl(newControl);
    
    // Get control details directly from AuditChecklistMapping
    const controlDetails = AuditChecklistMapping[newControl];
    if (controlDetails) {
      setControlDescription(controlDetails.description || '');
      setControlTestSteps(controlDetails.testSteps || '');
      setControlFrequency(controlDetails.frequency || '');
    }
  };

  const handleDescriptionChange = (event) => {
    setControlDescription(event.target.value);
  };

  const handleAIChange = (event) => {
    const newAI = event.target.value;
    setSelectedAI(newAI);
    props.setOutput(null);
    
    // Only validate files if switching to OpenAI and files exist
    if (newAI === 'openai' && files.length > 0) {
      const { isValid, errors } = validateFiles(files);
      if (!isValid) {
        setError(errors.join('\n'));
        setFiles([]); // Only clear files if validation fails
      }
    } else {
      setError(null); // Clear any existing errors
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateResponseStates = (data) => {
    props.setOverallAssessmentOutcome(data.overallAssessment);
    props.setOrganizationIdentificationOutcome(data.organizationIdentification);
    props.setSystemProcessIdentificationOutcome(data.systemProcessIdentification);
    props.setTimestampOutcome(data.timestamp);
    props.setResponseData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    props.setOutput('');
    setError(null);
    props.setResponseData(null);
    updateResponseStates({
      overallAssessment: null,
      organizationIdentification: null,
      systemProcessIdentification: null,
      timestamp: null
    });
  
    try {
      // Create control mapping with updated values
      const modifiedControl = {
        ...AuditChecklistMapping[selectedControl],
        description: controlDescription,
        testSteps: controlTestSteps,
        frequency: controlFrequency
      };
  
      const modifiedMapping = {
        ...AuditChecklistMapping,
        [selectedControl]: modifiedControl
      };
  
      if (selectedAI === 'claude') {
        const { response, parsedData } = await handleClaudeSubmit(
          files,
          selectedControl,
          modifiedMapping
        );
        props.setOutput(response);
        updateResponseStates(parsedData);
      } else {
        const response = await handleOpenAISubmit(
          files,
          selectedControl,
          modifiedMapping,
          (data) => {
            updateResponseStates(data);
          }
        );
        props.setOutput(response);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(error.message);
      props.setOutput(null);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  // Render component
  return (
    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
      <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
        Custom Framework
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
        Upload your documents and get an instant AI-generated quality analysis report.
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        {/* AI Selection */}
        <FormControl fullWidth>
          <InputLabel id="ai-select-label">Select AI Assistant</InputLabel>
          <Select
            labelId="ai-select-label"
            id="ai-select"
            value={selectedAI}
            label="Select AI Assistant"
            onChange={handleAIChange}
          >
            <MenuItem value="claude">{aiLabels['claude']}</MenuItem>
            <MenuItem value="openai">{aiLabels['openai']}</MenuItem>
          </Select>
        </FormControl>

        {/* Project Selection */}
        <FormControl fullWidth>
          <InputLabel id="project-select-label">Project Name</InputLabel>
          <Select
            labelId="project-select-label"
            id="project-select"
            value={selectedProject}
            label="Project Name"
            onChange={handleProjectChange}
          >
            {Object.keys(ProjectToProcessMapping).map((project) => (
              <MenuItem key={project} value={project}>
                {project}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Process Selection */}
        <FormControl fullWidth>
          <InputLabel id="process-select-label">Process Name</InputLabel>
          <Select
            labelId="process-select-label"
            id="process-select"
            value={selectedProcess}
            label="Process Name"
            onChange={handleProcessChange}
            disabled={!selectedProject}
          >
            {getAvailableProcesses().map((process) => (
              <MenuItem key={process} value={process}>
                {process}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Control Selection */}
        <FormControl fullWidth>
          <InputLabel id="control-select-label">Control Number and Title</InputLabel>
          <Select
            labelId="control-select-label"
            id="control-select"
            value={selectedControl}
            label="Control Number and Title"
            onChange={handleControlChange}
            disabled={!selectedProcess}
          >
            {getAvailableControls().map((control) => (
              <MenuItem key={control.id} value={control.id}>
                {control.id}: {control.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Control Description Text Area */}
        {selectedControl && (
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Control Description"
            value={controlDescription}
            onChange={(e) => setControlDescription(e.target.value)}
          />
        )}

        {/* Control Test Steps */}
        {selectedControl && (
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Control Test Plan Test Steps"
            value={controlTestSteps}
            onChange={(e) => setControlTestSteps(e.target.value)}
          />
        )}

        {/* Control Frequency Display */}
        {controlFrequency && (
          <Typography variant="body2" color="textSecondary">
            Control Frequency: {controlFrequency}
          </Typography>
        )}

        {/* File Input */}
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={handleFileButtonClick}
          >
            Upload supporting evidence
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files)}
          </FileNameDisplay>
        </CustomFileInput>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 2, fontWeight: 'bold' }}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Analyze supporting evidence'}
        </Button>
      </StyledForm>
    </Typography>
  );
}

export default CustomForm;