import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import MarketingPage from './marketing-page/MarketingPage';
import UnderConstructionPage from './marketing-page/UnderConstructionPage';
import UnifiedAIForm from './assistant-forms/AssistantView';
import LoginForm from './auth/LoginForm';
import PBCList from './assistant-forms/PBCList';
import ISOPBCList from './assistant-forms/ISO_PBCList';
import NIST_PBCList from './assistant-forms/NIST_PBCList';

// Import Montserrat font
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  // Check for GitHub token in localStorage
  const isAuthenticated = localStorage.getItem('github_token');
  
  if (!isAuthenticated) {
    // Redirect to login page with the return url
    return <Navigate to="/login" replace state={{ from: window.location.pathname }} />;
  }

  return children;
};

// Create a custom theme with the provided color palette and Montserrat font
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    allVariants: {
      fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#ed7d31', // color_3
    },
    secondary: {
      main: '#ed7d31', // color_3
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)', // color_2
      secondary: 'rgba(102, 102, 102, 1)', // color_4
    },
    background: {
      default: 'rgba(247, 247, 247, 1)', // color_6
      paper: 'rgba(255, 255, 255, 1)', // color_1
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat Fallback';
          src: local('Arial');
        }
        * {
          font-family: 'Montserrat', 'Montserrat Fallback', sans-serif !important;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px !important',
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<UnderConstructionPage />} />
          {/* <Route path="/" element={<MarketingPage />} /> */}
          {/* <Route path="/login" element={<LoginForm />} /> */}

          {/* Protected routes */}
          {/* <Route 
            path="/agents" 
            element={
              // <ProtectedRoute>
                <UnifiedAIForm />
              // </ProtectedRoute>
            } 
          />
          <Route 
            path="/pbc-list" 
            element={
                <PBCList />
            } 
          />
          <Route 
            path="/iso-pbc-list" 
            element={
                <ISOPBCList />
            } 
          />
          <Route 
            path="/nist-pbc-list" 
            element={
                <NIST_PBCList />
            } 
          /> */}
          
          {/* Catch all route - redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;