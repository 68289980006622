import React, { useState, useRef, useEffect } from 'react';
import { 
  Button, Typography, CircularProgress, Alert, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NISTFrameworkMapping from './nist_framework_mapping';
import { handleClaudeSubmit } from './modules/nist_claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from './modules/nist_openai_4o_module';

const aiLabels = {
  'openai': 'OpenAI',
  'claude': 'Claude'
};

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column', 
  gap: theme.spacing(3),
}));

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ExampleBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

function NISTForm(props) {
  // State management
  const [files, setFiles] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAI, setSelectedAI] = useState('openai');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [error, setError] = useState(null);

  const fileInputRef = useRef(null);
  const bottomRef = useRef(null);

  // Effects
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  });
  
  useEffect(() => {
    setIsFormValid(files.length > 0 && selectedSubcategory !== '');
  }, [files, selectedSubcategory]);

  // Event handlers
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }
    
    setFiles(newFiles);
    setError(null);
  };

  const handleCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    setSelectedSubcategory('');
  };

  const handleSubcategoryChange = (event) => {
    const newSubcategory = event.target.value;
    setSelectedSubcategory(newSubcategory);
  };

  const handleAIChange = (event) => {
    const newAI = event.target.value;
    setSelectedAI(newAI);
    props.setOutput(null);
    
    if (newAI === 'openai' && files.length > 0) {
      const { isValid, errors } = validateFiles(files);
      if (!isValid) {
        setError(errors.join('\n'));
        setFiles([]);
      }
    } else {
      setError(null);
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const getAvailableSubcategories = () => {
    return selectedCategory ? 
      Object.keys(NISTFrameworkMapping[selectedCategory]?.subcategories || {}) : [];
  };

  const updateResponseStates = (data) => {
    props.setOverallAssessmentOutcome(data.overallAssessment);
    props.setOrganizationIdentificationOutcome(data.organizationIdentification);
    props.setSystemProcessIdentificationOutcome(data.systemProcessIdentification);
    props.setTimestampOutcome(data.timestamp);
    props.setResponseData(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    props.setOutput('');
    setError(null);
    props.setResponseData(null);
    updateResponseStates({
      overallAssessment: null,
      organizationIdentification: null,
      systemProcessIdentification: null,
      timestamp: null
    });
  
    try {
      const frameworkData = {
        category: selectedCategory,
        subcategory: selectedSubcategory,
        details: NISTFrameworkMapping[selectedCategory]?.subcategories[selectedSubcategory]
      };
  
      if (selectedAI === 'claude') {
        const { response, parsedData } = await handleClaudeSubmit(
          files,
          selectedSubcategory,
          frameworkData
        );
        props.setOutput(response);
        updateResponseStates(parsedData);
      } else {
        const response = await handleOpenAISubmit(
          files,
          selectedSubcategory,
          frameworkData,
          (data) => {
            updateResponseStates(data);
          }
        );
        props.setOutput(response);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(error.message);
      props.setOutput(null);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  return (
    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
      <Typography variant="h4" component="h1" gutterBottom color="primary" fontWeight="bold" textAlign="center">
        NIST Cybersecurity Framework Analysis
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
        Upload your documents and get an AI-generated analysis based on NIST CSF criteria.
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        {/* AI Selection */}
        <FormControl fullWidth>
          <InputLabel id="ai-select-label">Select AI Assistant</InputLabel>
          <Select
            labelId="ai-select-label"
            id="ai-select"
            value={selectedAI}
            label="Select AI Assistant"
            onChange={handleAIChange}
          >
            <MenuItem value="claude">{aiLabels['claude']}</MenuItem>
            <MenuItem value="openai">{aiLabels['openai']}</MenuItem>
          </Select>
        </FormControl>

        {/* Category Selection */}
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            label="Category"
            onChange={handleCategoryChange}
          >
            {Object.keys(NISTFrameworkMapping).map((category) => (
              <MenuItem key={category} value={category}>
                {category}: {NISTFrameworkMapping[category].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Category Description */}
        {selectedCategory && (
          <Typography variant="body2" sx={{ px: 2, py: 1, bgcolor: 'grey.50', borderRadius: 1 }}>
            {NISTFrameworkMapping[selectedCategory].description}
          </Typography>
        )}

        {/* Subcategory Selection */}
        <FormControl fullWidth>
          <InputLabel id="subcategory-select-label">Subcategory</InputLabel>
          <Select
            labelId="subcategory-select-label"
            id="subcategory-select"
            value={selectedSubcategory}
            label="Subcategory"
            onChange={handleSubcategoryChange}
            disabled={!selectedCategory}
          >
            {getAvailableSubcategories().map((subcategory) => (
              <MenuItem key={subcategory} value={subcategory}>
                {subcategory}: {NISTFrameworkMapping[selectedCategory].subcategories[subcategory].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Examples Display */}
        {selectedCategory && selectedSubcategory && (
          <ExampleBox>
            <Typography variant="subtitle2" gutterBottom>
              Implementation Examples:
            </Typography>
            {NISTFrameworkMapping[selectedCategory].subcategories[selectedSubcategory].examples.map((example, index) => (
              <Typography key={index} variant="body2" sx={{ mt: 1 }}>
                • {example}
              </Typography>
            ))}
          </ExampleBox>
        )}

        {/* File Input */}
        <CustomFileInput>
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
          />
          <FileInputButton
            variant="outlined"
            component="span"
            onClick={handleFileButtonClick}
          >
            Upload supporting evidence
          </FileInputButton>
          <FileNameDisplay variant="body2">
            {renderFileNames(files)}
          </FileNameDisplay>
        </CustomFileInput>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          sx={{ mt: 2, fontWeight: 'bold' }}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Analyze evidence against NIST CSF'}
        </Button>
      </StyledForm>
    </Typography>
  );
}

export default NISTForm;